import { Injectable } from '@angular/core';
import { PopoverController, ToastController, ModalController, LoadingController, LoadingOptions, AlertController, PopoverOptions } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UiServices {
  toastMsg: HTMLIonToastElement;
  loading: HTMLIonLoadingElement;
  modal: HTMLIonModalElement;

  constructor(
    private toastCtrl: ToastController, 
    private popoverCtrl: PopoverController,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private translateSv: TranslateService
  ) { }

  async showToast(msg?){
    // if(window.location.pathname !== '/cart'){
      if(this.toastMsg){
        await this.toastMsg.dismiss();
      }
      this.toastMsg = await this.toastCtrl.create({
        message: msg,
        duration: 3000,
        cssClass: 'toastMsg',
        htmlAttributes: { tabindex: undefined }
      });
      await this.toastMsg.present();
    // }
  }

  async showPopover(component, componentProps?, cssClass?, event?){
    const popover = await this.popoverCtrl.create({
      component,
      componentProps,
      cssClass,
      event,
    });
    await popover.present();
    const { data, role } = await popover.onDidDismiss();
    return { data, role };
  }

  async showModal(component, componentProps?, cssClass?, backdropDismiss?){
    this.modal = await this.modalController.create({
      component,
      componentProps,
      cssClass,
      backdropDismiss
    });

    await this.modal.present();
    const { data, role } = await this.modal.onDidDismiss();
    return { data, role };
  }

  async showLoading(message = 'POR_FAVOR_ESPERA') {
    message = this.translateSv.instant(message);
    if(this.loading){
      await this.loading.dismiss();
    }
    this.loading = await this.loadingController.create({
      message,
      spinner: 'bubbles'
    });
    await this.loading.present();
  }

  async presentAlert(message, showCancelButton = true, okText = 'ACEPTAR') {
    const header = this.translateSv.instant('ALERTA');
    okText = this.translateSv.instant(okText);
    const cancelTxt = this.translateSv.instant('CANCELAR');
    const buttons = [
      {
        text: okText,
        role: 'accept'
      }
    ]
    if(showCancelButton){
      buttons.push({
        text: cancelTxt,
        role: 'cancel'
      })
    }
    const alert = await this.alertController.create({
      header,
      message,
      buttons
    });
    
    await alert.present();
    const { role }  = await alert.onDidDismiss();
    return { role };
  }
}
