import { Component, ChangeDetectionStrategy, AfterViewInit , ElementRef, EventEmitter, Output, Renderer2, ViewChild, Input } from '@angular/core';
import { BalanceService } from 'src/app/services/balance.service';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent implements AfterViewInit {
  @Input() dataFilter: any = {};
  @Output() emitFiler = new EventEmitter();
  public sponsors = [];
  public showFilter = false;
  private _domainId = environment.domainId;
  public filter: any = {
    category: '',
    orden: '',
    promo: '',
    from: '',
    upTo: '',
    sponsor: '',
    pag: 1
  }

  constructor(
    private httpSv: HttpService,
    private balanceSv: BalanceService
  ) { }

  trackByItems(index, item){
    return item.id
  }
  
  async ngAfterViewInit(){
    try{
      const sponsors: any = await this.httpSv.itemIndex('client/sponsor', `${this._domainId}`);
      this.sponsors = sponsors.data;
      // const res: any = await this.httpSv.itemIndex('category', `//${this._domainId}`);
      // this.categories = res.data;
    }catch(err){
      console.error(err);
    }
  }

  doShowFilter(ev?: any){
    if(ev){
      if(!ev?.target?.classList?.contains('contentFilter')){
        return
      }
    }
    this.removeFilter();
  }

  removeFilter(){
    this.showFilter = !this.showFilter;
  }

  setFilters(type?){
    this.removeFilter();
    if(this.filter.from){
      if(this.balanceSv.currency?.type === 1){
        this.filter.from = String(Math.round((Number(this.filter.from) / this.balanceSv.currency.change))); 
      }else{
        this.filter.from = String(Math.round((Number(this.filter.from) * this.balanceSv.currency.change))); 
      }
    }

    if(this.filter.upTo){
      if(this.balanceSv.currency?.type === 1){
        this.filter.upTo = String(Math.round((Number(this.filter.upTo) / this.balanceSv.currency.change))); 
      }else{
        this.filter.upTo = String(Math.round((Number(this.filter.upTo) * this.balanceSv.currency.change))); 
      }
    }
    if(type === 'clear'){
      this.filter =  {
        category: '',
        orden: '',
        promo: '',
        from: '',
        upTo: '',
        sponsor: '',
        pag: 1
      }
    }

    this.emitFiler.emit(this.filter);
    this.filter.from = '';
    this.filter.upTo = '';
  }

}
