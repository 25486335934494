import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ParamServicesService } from 'src/app/services/param-services.service';
import { PwaService } from 'src/app/services/pwa.service';

@Component({
  selector: 'app-pwa',
  templateUrl: './pwa.component.html',
  styleUrls: ['./pwa.component.scss'],
})
export class PwaComponent implements OnInit {
  @Input() data: any;
  
  images: any[];

  constructor(
    private paramSv: ParamServicesService,
    private navCtrl: NavController,
    private pwaSv: PwaService
  ) { }

  async ngOnInit() {
   

    try{
      const res: any[] = await this.paramSv.getImages('', '0');
      const image = res?.find(item => item.name === this.data?.type);
      if(!image) return;
      this.images = image.images;
    }catch(err){
      console.error(err);
    }
  }

  goTo(){
    if(this.data.type === 'pwa'){
      this.downloadPwa();
    }else{
      this.navCtrl.navigateForward(this.data.type);
    }
  }

  async downloadPwa(){
    if (this.pwaSv.deferredPrompt) {
      this.pwaSv.deferredPrompt?.prompt();
      const { outcome } = await this.pwaSv.deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        this.pwaSv.backbutton = true;
      }
    }
  }

}
