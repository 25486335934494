import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormServices } from './form-services.service';
import { HttpService } from './http.service';
import { UiServices } from './ui-services';

@Injectable({
  providedIn: 'root'
})

export class FileService {
  imagesUploaded: any[] = [];
  imagesUploadedFile: File[] = []
  filesUploaded: File[] = [];
  totalUploaded: number = 0;
  actualUploaded: number = 0;
  copy = false;
  copyObs = new EventEmitter();

  constructor(
    private uiSv: UiServices,
    private httpSv: HttpService,
    private formSv: FormServices,
    private trSv: TranslateService,
  ) {}

  uploadImages(incomingFiles, acceptPdf?){
    return new Promise<any[]>(async (resolve, reject) => {
      const files = incomingFiles;
      this.totalUploaded = files.length;
      if(files[0] && (!files[0].type.includes('image') && (acceptPdf && !files[0].type.includes('pdf')))){
        const tr = this.trSv.instant('SUBE_UN_ARCHIVO_VALIDO');
        await this.uiSv.showToast(tr);
        reject('Sube un archivo válido');
        return;
      }
      // this.imagesUploadedFile.push(...incomingFiles);
      for(let img of files){
        const images = await this.isLoadedImages(img);
        this.imagesUploaded.push(images);
        this.actualUploaded++;
        if(this.totalUploaded === this.actualUploaded){
          resolve(this.imagesUploaded);
        }
      }
    })
  }

  isLoadedImages(img){
    return new Promise((resolve) => {
      const reader = new FileReader();
      let image: any = undefined;
      reader.readAsDataURL(img);
      reader.onload = (e) => {
        image = { file: img, url: e.target.result };
      }

      reader.onloadend = e => resolve(image);
    })
  }

  uploadFiles(incomingFiles){
    return new Promise<any[]>((resolve, reject) => {
      const files: FileList = incomingFiles.target.files;
      const arrFiles = [];
      for(let i = 0; i < files.length; i++){
        arrFiles[i] = files[i];
      }
      resolve(arrFiles);
    })
  }

 

  setAnimBadge(tagInput, badge, tags: any[]){
    let canPush = true;
    if(tagInput.value === '' || tagInput.value.length < 3){
      const tr = this.trSv.instant('EL_TAG_DEBE_CONTENER_AL_MENOS_3_LETRAS');
      this.uiSv.showToast(tr);
      canPush = false;
    }

    if(!canPush) return canPush;
    tags?.push(tagInput.value); 
    tagInput.value = '';
    return canPush
  }

  emptyArr(){
    this.imagesUploaded = [];
    this.imagesUploadedFile = [];
    this.filesUploaded = [];
    this.actualUploaded = 0;
  }

  fixArrayImg(images, saveInUpload){
    const formData = new FormData();
    for(const key of Object.keys(images)){
      const imagesArr: any[] = images[key];
      if(saveInUpload){
        const splitImg = imagesArr.join(',');
        if(!splitImg.includes('fakepath')){
          formData.append(key, splitImg);
        }
      }else{
        for(const image of imagesArr){
          if(!image.file.includes('fakepath')){
            formData.append(key, image.file);
          }
        }
      }
    }
    return formData;
  }

  async deleteProduct(product, url, msg){
    try{
      const tr = this.trSv.instant('DESEAS_ELIMINAR');
      const { role } = await this.uiSv.presentAlert(`${tr} ${product.name}?`);
      if(role === 'accept'){
        const deletingMsg = this.trSv.instant('ELIMINANDO');
        await this.uiSv.showLoading(`${deletingMsg} ${msg}...`);
        await this.httpSv.itemAction({id: product.id}, url, 'destroy');
        await this.uiSv.loading?.dismiss();
        const trDel = this.trSv.instant('ELIMINADO_EXITOSAMENTE');
        await this.uiSv.showToast(`${msg} ${trDel}.`);
        return true;
      }else{
        return false;
      }
    }catch(err){
      console.error(err);
    }
  }
  
  async getProduct(
    { product, action, changeAction }, 
    id, 
    url, 
    msg, 
    savedForm?, 
    reload = 'reload'
  ){
    try{
      this.copy = false;
      const productId = product.id;
      if(product.categories){
        product.categories = product?.categories.map(item => {
          if(typeof item === 'number'){
            return item;
          }else{
            return item?.id
          }
        }, []);
        
      }

      if(product.branchContactId){
        product.branchContactId = product?.branchContactId.map((item) => {
          if(typeof item === 'number'){
            return item;
          }else{
            return item?.id
          }
        }, []);
      }
      product?.isActive ? product.isActive = true : product.isActive = false;
      product?.existence ? product.existenceNull = false : product.existenceNull = true;
      if(action === 'edit'){
        if(id !== productId){
          id = productId;
          this.formSv.clearFormSrc.next();
        }
      }else if(action === 'delete'){
        const res = await this.deleteProduct(product, url, msg);
        if(res){
          this.formSv.deleteListProductSrc.next(productId);
          if(id === product.id){
            this.formSv.clearFormSrc.next(reload);
            id = undefined;
          }
          product = undefined;
        }
      }else if (action === 'copy'){
        id = undefined;
        this.copy = true;
        this.copyObs.emit();
      }
      if(!changeAction){
        // this.formSv.clearFormSrc.next();
      }
      if(savedForm && savedForm.categories && savedForm.categoriesChild){
        savedForm.categories.push(...savedForm.categoriesChild)
      }
      savedForm = product;
      return ({ id, savedForm });
    }catch(err){
      console.error(err);
      
    }
  }
}
