import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IonMenu, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MultioComponent } from './components/multio/multio.component';
import { SplashComponent } from './components/splash/splash.component';
import { BalanceService } from './services/balance.service';
import { CartServiceService } from './services/cart-service.service';
import { HttpService } from './services/http.service';
import { LangService } from './services/lang.service';
import { LoginService } from './services/login.service';
import { MetadataService } from './services/metadata-service.service';
import { ParamServicesService } from './services/param-services.service';
import { PwaService } from './services/pwa.service';
import { StorageService } from './services/storage.service';
import { UiServices } from './services/ui-services';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit{
  @ViewChild('menu') ionMenu: IonMenu;
  @HostListener('document:DOMContentLoaded', ['$event'])
  onDomContentLoaded(event: Event) {
    console.log('DOMContentLoaded TODO: your logic');
  }
  @HostListener('document:load', ['$event'])
  onLoad(event: Event) {
    console.log('DOMLoad TODO: your logic');
  }

  public optsMenu:any[] = [];
  public roleId = undefined;
  public domainId = environment.domainId;
  public staticOpts: any[] = [
    {
      name: 'PROMOCIONES',
      icon: '/assets/icons/promos.svg',
      url: `marketplace?domainId=${this.domainId}&catalogue=&pag=1&search=&category=&orden=&promo=1&from=&upTo=`
    },
    {
      name: 'LO_MAS_RECIENTE',
      icon: '/assets/icons/reciente.svg',
      url: `marketplace?domainId=${this.domainId}&catalogue=&pag=1&search=&category=&orden=newProducts&promo=&from=&upTo=`
    },
    {
      name: 'CAMBIAR_MONEDA',
      icon: '/assets/icons/moneda.svg',
      changeCurrency: true
    },
    {
      name: 'MI_CUENTA',
      icon: '/assets/icons/Usuario.svg',
      url: 'profile'
    },
   
  ]

  public buttons = undefined;
  public loaded = false;
  public colors: any[];
  public homeSections: any[];
  public subscriber: Subscription;

  constructor(
    private navCtrl: NavController,
    public paramSv: ParamServicesService,
    private elementRef: ElementRef,
    private router: Router,
    private uiSv: UiServices,
    private storageSv: StorageService,
    public loginSv: LoginService,
    private httpSv: HttpService,
    private cartSv: CartServiceService,
    public balanceSv: BalanceService,
    private pwaSv: PwaService,
    private metaSv: MetadataService,
    private langSv: LangService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    // this.metaSv.subscribeData();
  }

  async closeMenu(){
    await this.ionMenu.close(true);
  }

  async ngOnInit() {
    try{
      console.log(typeof window);
      if (!isPlatformBrowser(this.platformId)) {
        console.log('is not browser');
        return false;
      }
      console.log('isBrowser');
      
      this.metaSv.subscribeData();
      this.pwaSv.pwa();
      await this.loginSv.getTokenUser();
      setTimeout(async () => {
        if(this.loginSv.token){
          const data: any = await this.httpSv.itemIndex('auth');
          await this.loginSv.saveUser(data.user);
        }
      })
      this.loginSv.emitLogin.subscribe(() => {
        this.addOptions();
      });

      this.loginSv.emitLogout.subscribe(() => {
        this.comprobeAdminOption();
        this.comprobeLogin();
      });

      await this.uiSv.showModal(SplashComponent, undefined, 'splash');
      if(this.paramSv.wallet){
        if(this.paramSv.wallet.value.value === '1'){
          this.staticOpts.unshift({
            name: 'WALLET',
            icon: '/assets/icons/wallet.svg',
            url: 'wallet'
          })
        }
      };
    
      this.colors = this.paramSv.colors;
      this.buttons = this.paramSv.buttons;
      this.homeSections = this.paramSv.homeSections;
      const fontFamily = this.paramSv?.styles?.find(item => item.text === 'Fuente')?.selected;
      this.elementRef.nativeElement.style.setProperty('--ion-font-family', `${fontFamily}, sans-serif`);

      let existCurrency: any = localStorage.getItem('currency');
      if(existCurrency === 'undefined') existCurrency = undefined;
      if(!existCurrency){
        localStorage.setItem('currency', JSON.stringify(this.paramSv.defaultCurrency));
        this.balanceSv.currencySource.emit(this.paramSv.defaultCurrency);
      }else if(existCurrency){
        let compareActualCurrency = JSON.parse(existCurrency);
        if(compareActualCurrency?.name === this.paramSv.defaultCurrency?.name){
          localStorage.setItem('currency', JSON.stringify(this.paramSv.defaultCurrency));
          this.balanceSv.currencySource.emit(this.paramSv.defaultCurrency);
        }else{
          const currencies: any[] = await this.balanceSv.getCurrencies();
          const updateCurrency = currencies.find(item => item.name === compareActualCurrency.name);
          localStorage.setItem('currency', JSON.stringify(updateCurrency));
          this.balanceSv.currencySource.emit(updateCurrency);
        }
      }
      await this.setColors();
      if(this.paramSv.requiredAuth){
        this.subscriber = this.router.events.pipe(
          filter(event => event instanceof NavigationEnd)
        ).subscribe((event) => {
          const logined = localStorage.getItem('logined');
          if(logined === '1'){
            this.subscriber.unsubscribe();
          }else{
            this.navCtrl.navigateForward('auth')
          }
        });
      }
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        const valueButtons = this.buttons?.value;
        for(const key of Object.keys(valueButtons)){
          const value = valueButtons[key];
          if(event.matches){
            if(value?.darkBtnProperties){
              for(const style of Object.keys(value?.darkBtnProperties)){
                this.elementRef.nativeElement.style.setProperty(`--${key}${style}`, value?.darkBtnProperties[style]);
              }
            }
          }else{
            if(value?.lightBtnProperties){
              for(const style of Object.keys(value?.lightBtnProperties)){
                this.elementRef.nativeElement.style.setProperty(`--${key}${style}`, value?.lightBtnProperties[style]);
              }
            }
          }
        }
        
        this.colors.map(color => {
          if (event.matches) {
            this.elementRef.nativeElement.style.setProperty(color.name, color.darkColor);
          } else {
            this.elementRef.nativeElement.style.setProperty(color.name, color.color);
          }
        });
        this.homeSections.map((section) => {
          if (event.matches) {
            this.elementRef.nativeElement.style.setProperty(`--${section.type}`, section.darkBackgound);
          } else {
            this.elementRef.nativeElement.style.setProperty(`--${section.type}`, section.backgound);
          }
        });

      });

      this.addOptions();
    }catch (err){      
      console.error(err);
    }
  }

  async comprobeLogin(){
    const existLogin = !this.staticOpts.find(item => item.url === 'auth');
    if(existLogin){
      this.staticOpts.unshift({
        name: 'INICIAR_SESION',
        icon: '/assets/icons/home-outline.svg',
        url: 'auth'
      });
    }
  }

  addOptions(){
    setTimeout(async () => {
      const data = await this.storageSv.get('userInfo');
      this.roleId = data?.user?.rolId;
      if(!this.paramSv.ableLogin?.canLogin){
        this.staticOpts = this.staticOpts.filter(item => item.url !== 'profile');
      }
      if(!this.paramSv.headerParam[1].activate){
        this.staticOpts = this.staticOpts.filter(item => item.name !== 'CAMBIAR_MONEDA');
      }
      if(!this.paramSv.headerParam[4].activate || !this.paramSv.ableLogin?.canLogin){
        this.staticOpts = this.staticOpts.filter(item => item.name !== 'ORDENES_PENDIENTES');
      }

      if(this.paramSv.availableViews){
        const views: any[] = this.paramSv.availableViews.value.value;
        const availableMeet = views.find(item => item.view === 'Citas')?.visible;
        const availableSubscriptions = views.find(item => item.view === 'Suscripciones')?.visible;
        const existSubs = !!this.staticOpts.find(item => item.name === 'SUSCRIPCIONES');
        if(availableSubscriptions && !existSubs){
          this.staticOpts.unshift({
            name: 'SUSCRIPCIONES',
            iconName: 'calendar-outline',
            url: 'inscriptions'
          })
        }
        if(availableMeet){
          if(!this.staticOpts.find(x => x.name === 'MI_CALENDARIO')){
            this.staticOpts.unshift({
              name: 'MI_CALENDARIO',
              iconName: 'calendar-number-outline',
              url: 'operator'
            })
          }
        }
      }


      if(this.paramSv.ableLogin === undefined || this.paramSv.ableLogin?.canLogin){
        if(!this.staticOpts.find(x => x.name === 'SALIR')){
          this.staticOpts.push({
            name: 'SALIR',
            icon: '/assets/icons/salir.svg',
            url: 'logout'
          });
        }
      }
      const canLogin = this.paramSv.ableLogin === undefined || this.paramSv.ableLogin?.canLogin
      if(canLogin){
        if(!this.staticOpts.find(x => x.name === 'ORDENES_PENDIENTES')){
          this.staticOpts.unshift(
            {
              name: 'ORDENES_PENDIENTES',
              iconName: 'reader-outline',
              url: 'pending-orders'
            },
            {
              name: 'FAVORITOS',
              icon: '/assets/icons/heart-outline.svg',
              url: 'favorites'
            },
          )
        }
      }
      if(!data){
        if(canLogin){
          this.staticOpts.unshift(
            {
              name: 'INICIAR_SESION',
              icon: '/assets/icons/home-outline.svg',
              url: 'auth'
            }
          );
        }
      }else{
        const indexLogin = this.staticOpts.findIndex((item) => item.url === 'auth');
        if(indexLogin !== -1){
          this.staticOpts.splice(indexLogin, 1);
        }
      }
      if(this.roleId === 3){
        this.staticOpts.unshift({
          name: 'ADMINISTRADOR',
          icon: '/assets/icons/administrador.svg',
          url: 'admin'
        })
      }
    }, 100)
  }

  comprobeAdminOption(){
    this.staticOpts = this.staticOpts.filter(item => item.url !== 'admin' && item.url !== 'operator');
  }

  setColors(){
    return new Promise((resolve) => {
      const matches = window.matchMedia('(prefers-color-scheme: dark)').matches;
      const valueButtons = this.buttons?.value;
      if(valueButtons){
        for(const key of Object.keys(valueButtons)){
          const value = valueButtons[key];
          if(matches){
            if(value?.darkBtnProperties){
              for(const style of Object.keys(value?.darkBtnProperties)){
                this.elementRef.nativeElement.style.setProperty(`--${key}${style}`, value?.darkBtnProperties[style]);
              }
            }
          }else{
            if(value?.lightBtnProperties){
              for(const style of Object.keys(value?.lightBtnProperties)){
                this.elementRef.nativeElement.style.setProperty(`--${key}${style}`, value?.lightBtnProperties[style]);
              }
            }
          }
        }
      }

      this.homeSections.forEach((section) => {
        if (matches) {
          this.elementRef.nativeElement.style.setProperty(`--${section.type}`, section.darkBackgound);
        } else {
          if(section?.backgound){
            this.elementRef.nativeElement.style.setProperty(`--${section.type}`, section.backgound);
          }
        }
      });
      
      this.colors.forEach(color => {
        if(matches){
          this.elementRef.nativeElement.style.setProperty(color.name, color.darkColor);
        }else{
          this.elementRef.nativeElement.style.setProperty(color.name, color.color);
        }
      });

      this.elementRef.nativeElement.style.setProperty('--ion-color-primary', 'var(--primary--background)');
      resolve(true);
    })
  }

  trackByItems(index, item){
    return item.id ? item.id : item.name;
  }
}