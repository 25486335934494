import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  private langage: string = 'es';
  private _langages: string[] = undefined;
  private _domainId = environment.domainId;

  constructor(
    private translateService: TranslateService,
    private httpSv: HttpService
  ) {
    this.initLanguage()
  }

  async initLanguage(){
    try{
      const data = await this.httpSv.getParam('lang', this._domainId);
      const lang = data ? JSON.parse(data.value) : 'es';
      this.translateService.addLangs(['es', 'en']);
      this._langages = this.translateService.getLangs();
      this.translateService.setDefaultLang(lang);
    }catch(err){
      console.error(err);
    }
  }

  async changeLangage(langage: string) {
    const useLangage = langage || 'es';
    this.translateService.use(useLangage);
    localStorage.setItem('langage', langage);
    this.langage = langage;
  }
}
