import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BalanceService } from 'src/app/services/balance.service';
import { CartServiceService } from 'src/app/services/cart-service.service';
import { HttpService } from 'src/app/services/http.service';
import { LoginService } from 'src/app/services/login.service';
import { ParamServicesService } from 'src/app/services/param-services.service';
import { UiServices } from 'src/app/services/ui-services';

@Component({
  selector: 'app-product-cart',
  templateUrl: './product-cart.component.html',
  styleUrls: ['./product-cart.component.scss'],
})

export class ProductCartComponent implements OnInit, OnDestroy {
  @Input() item = undefined;
  @Output() emitDelete = new EventEmitter();
  public currency: any;
  public currencyName = 'USD';
  public typeProduct = undefined;
  private _subscription: Subscription = new Subscription();
  

  constructor(
    public cartSv: CartServiceService,
    private httpSv: HttpService,
    private uiSv: UiServices,
    private balanceSv: BalanceService,
    public paramSv: ParamServicesService,
    private translateSv: TranslateService,
  ) { }

  ngOnInit() {
    if(this.item.subscriptionId){
      this.typeProduct = 'subscription';
    }else{
      this.typeProduct = 'product';
    }

    if(this.balanceSv.currency){
      this.currency = this.balanceSv.currency;
      this.currencyName = this.currency.symbol;
    }
    this._subscription.add(
      this.balanceSv.currencySource
        .subscribe((currency) => {
          this.currency = currency;
          this.currencyName = currency.symbol;
        })
    )
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  async countProduct(item, count){
    try{
      // const { cantidad } = await this.cartSv.countProducts(type, item.quantity);
      if(item.quantity === count){
        return false;
      }
      item.quantity = count;
      const product = { ...item };
      product.id = item.productId || item.id;
      await this.cartSv.addCart(product, false);
    }catch(err){
      console.error(err);
    }
  }

  async deleteProduct(){
    try{
      const tr = this.translateSv.instant('DESEAS_ELIMINAR_EL_PRODUCTO');
      const { role } = await this.uiSv.presentAlert(`${tr} ${this.item.name}?`);
      if(role !== 'accept'){
        return false;
      }
      await this.uiSv.showLoading();
      const id = this.item.id || this.item.meetingId;
      const cartId = this.item.cartId;
      if(!this.cartSv.logined){
        const deletedProduct = this.cartSv.cart.filter(item => (item.id || item.meetingId) !== id);
        await this.cartSv.saveCart(deletedProduct, this.item);
        const translate = this.translateSv.instant('PRODUCTO_ELIMINADO');
        await this.uiSv.showToast(translate);
        this.emitDelete.emit();
        await this.uiSv.loading.dismiss();
        return true;
      }
      // const productId = this.item.productId;
      const res: any = await this.httpSv.itemAction({id, cartId}, 'cart', 'destroy');
      if(res.status){
        this.setQuantity({detail: {checked: false}}, this.item);
        await this.cartSv.saveCart(res.data[0].products, this.item);
        const type = this.item.meetingId ? 'Servicio' : 'Producto';
        const translate = this.translateSv.instant('ELIMINADO');
        await this.uiSv.showToast(`${type} ${translate}`);
        this.emitDelete.emit();
      }
      await this.uiSv.loading.dismiss();
    }catch(err){
      console.error(err);
      await this.uiSv.loading.dismiss();
    }
  }

  async setQuantity(ev, product){
    let totalQuantityProduct = 0;
    for(let i = 0; i < product.quantity; i++){
      totalQuantityProduct += product.price;
    }
    if(ev.detail.checked === false){
      this.cartSv.total -= product.quantity;
      this.cartSv.totalQuantity -= totalQuantityProduct;
      const translate = this.translateSv.instant('EL_PRODUCTO_QUEDARA_GUARDADO_EN_TU_CARRITO');
      await this.uiSv.showToast(translate);
    }else{
      this.cartSv.total += product.quantity;
      this.cartSv.totalQuantity += totalQuantityProduct;
    }
    this.cartSv.totalSrc.next({emit: false, total: this.cartSv.total});
  }
}
