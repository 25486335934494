import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BranchComponent implements OnInit, AfterViewInit {
  @ViewChild('brancSlides', { static: true }) brancSlides: IonSlides;
  @Input() data: any;
  // resolution = window.matchMedia('(max-width: 578px)').matches;

  constructor() { }

  ngOnInit() {
    this.data.branchContact = this.data.branchContact.filter(item => item.maps && item.name);
    // this.data.branchContact.map(item => {
    //   let b =  /src="(https:\/\/[^"]+)"/.exec(item.maps);
    //   if(b){
    //     const map = b[1];
    //     item.maps = map;
    //   }
    // })
  }

  ngAfterViewInit(): void {
    // this.data.branchContact.map((item, index) =>  {
    //   this.getMap(index, item.maps);
    // })
  }

  getMap(index, maps){
    // if(!maps){
    //   return
    // }
    // const element = document.querySelector(`.map${index}`);
    // const iframe = document.createElement('iframe');
    // iframe.setAttribute('src', maps);
    // iframe.setAttribute('class', 'iframeMap');
    // iframe.setAttribute('width', '100%');
    // iframe.setAttribute('height', this.resolution ? '300' : '450');
    // iframe.setAttribute('style', 'border:0; border-radius: 8px');
    // iframe.setAttribute('allowfullscreen', '');
    // iframe.setAttribute('loading', 'lazy');
    // iframe.setAttribute('referrerpolicy', 'no-referrer-when-downgrade');
    // element.appendChild(iframe);
  }

  async moveTo(direction){
    await direction === 'next' ? this.brancSlides.slideNext() : this.brancSlides.slidePrev();
  }

  trackByItems(index, item){
    return item.id
  }
}
