import { Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CalendarService } from 'src/app/pages/calendar/services/calendar.service';
import { UiServices } from 'src/app/services/ui-services';
import { ShowTermsComponent } from '../show-terms/show-terms.component';

declare var FullCalendar;

@Component({
  selector: 'app-calendar-date',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  @Input() operator: any = undefined;
  @Input() blockDays = true;
  @Input() blockedTurns: any[] = undefined;
  @Output() emitMeet = new EventEmitter();
  @Input() set date(meetingData: any){
    if(meetingData){
      const value = meetingData.data;
      this._businessHours = [];
      this.loading = true;
      const availableDays = [0,1,2,3,4,5,6];
      let unWorkDays = [];
      let workDays = [];
      this._duration = value.duration || 1;
      this._heightContent = ((this._duration / 60) * 24) * 2;
      const scheduleOperator: any[] = value.item;
      this.calendar && this.calendar.destroy();
      scheduleOperator.map(item => {
        const days: any[] = item.day.split(',');
        item.startTime = item.init;
        item.endTime = item.end;
        const { initToMs, endTimeToMs } = this.timeToMs(item)
        this.blockTurns(item);
        days.map(day => {
          day = Number(day);
          const existBusinessDay = this.daysOfWeek.findIndex(schedule => schedule.day === day);
          if(existBusinessDay !== -1){
            this.daysOfWeek[existBusinessDay].hours += (endTimeToMs - initToMs) / 3600000;
          }else{
            const businessDays = {
              day,
              hours: (endTimeToMs - initToMs) / 3600000,
            }
            this.daysOfWeek.push(businessDays)
          }
          const existDay = !!workDays.find(workDay => workDay === day);
          if(!existDay){
            workDays.push(day);
          }
        })
      })
      availableDays.map(availableDay => {
        const existDay = !!workDays.find(workDay => workDay === availableDay);
        if(!existDay){
          unWorkDays.push(availableDay);
        }
      })
      setTimeout(() => {
        if(unWorkDays.join(',') === availableDays.join(',')){
          unWorkDays = [];
        }
        this._hiddenDays = unWorkDays || [];
        this.setCalendar();
        this.calendar.gotoDate(meetingData.actualDate);
      },1000)
    }
  };
  @Input() set meet(value: any[]){
    if(value){
      
      this._events = [];
      value.map(item => {
        item.startTime = item.startHour;
        item.endTime = item.endHour;
        const { initToMs, endTimeToMs } = this.timeToMs(item);
        const date = new Date(item.date);
        const fixMont = date.getMonth() + 1;
        const fixDate = date.getDate();
        const shortDate = `${date.getFullYear()}-${fixMont < 10 ? '0' + fixMont : fixMont}-${fixDate < 10 ? '0' + fixDate : fixDate}`;
        const timeToHour = (endTimeToMs - initToMs) / 3600000;
        const existDateOnEvent = this._eventTime.findIndex(event => event.date === shortDate);
        const actualDay = date.getDay();
        if(existDateOnEvent !== -1){
          const schedule = this.daysOfWeek.find(schedule => schedule.day === actualDay);
          this._eventTime[existDateOnEvent].busySchedule += timeToHour
          const availableTimeAndDuration = this._eventTime[existDateOnEvent].busySchedule + (this._duration / 60);
          if(availableTimeAndDuration > schedule?.hours){
            this._eventTime[existDateOnEvent].available = false;
          }
        }else{
          this._eventTime.push({
            date: `${shortDate}`,
            busySchedule: timeToHour,
            available: true
          })
        }
        const splitedDate = date.toISOString().split('T');
        const obj = {
          daysOfWeek: [date.getDay()],
          date: date,
          startTime: item.startHour,
          endTime: item.endHour,
          title: item.title || ''
        }
        this._reserved.push(obj);
        this._events.push({
          id: item.id,
          title: item.title || 'Reservado', // a property!
          start: `${splitedDate[0]}T${item.startHour}`,
          end: `${splitedDate[0]}T${item.endHour}`,
          color: "#a0a0a0",
          className: item.class || 'reservedEvent'
        })
      })
    }
  }
 
  @ViewChild('calendar', { static: true }) calendarEl: ElementRef<HTMLDivElement>;

  public lastOperatorName = '';
  public calendar = undefined;
  public loading = true;
  public daysOfWeek: any[] = [];
  private _events = [];
  private _eventTime = [];
  private _hiddenDays: any[] = [];
  private _businessHours: any[] = [];
  private _reserved: any[] = [];
  private _duration: number = undefined;
  private _body = undefined;
  private _heightContent = undefined;
  private _calendarMonth = undefined;
  
  constructor(
    private uiSv: UiServices,
    private calendarSv: CalendarService,
    private trSv: TranslateService
  ) { }

  ngOnInit() {
  }

  blockTurns(item){
    let days: any[] = item.day.split(',');
    item.startTime = item.init;
    item.endTime = item.end;
    const obj = {
      daysOfWeek: days,
      startTime: item.init,
      endTime: item.end
    }
    this._businessHours.push(obj);
  }

  setCalendar(){
    this.calendar = new FullCalendar.Calendar(this.calendarEl.nativeElement, {
      initialView: 'dayGridMonth',
      allDaySlot: false,
      customButtons: {
        changeToMonth: {
          text: 'Mes',
          click: () => {
            this.clickedDate(null, 'dayGridMonth');
          }
        },
        changeToDay: {
          text: 'Día',
          click: () => {
            this.clickedDate(null, 'timeGrid');
          }
        },
        prevDate: {
          icon: 'chevron-left',
          click: () => {
            this.controlChange('prev');
          }
        },
        nxtDate: {
          icon: 'chevron-right',
          click: () => {
            this.controlChange('next');
          }
        }
      },
      headerToolbar: {
        start: 'today changeToMonth,changeToDay', // will normally be on the left. if RTL, will be on the right
        center: 'title',
        right: 'prevDate,nxtDate'
      },
      hiddenDays: this._hiddenDays,
      buttonText: {
        today: 'Hoy',
        dayGridMonth: 'Mes',
        timeGrid: 'Día'
      },
      slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit', 
        hour12: true,
      },
      eventTimeFormat: {    
        hour: '2-digit', 
        minute: '2-digit', 
        hour12: true 
      },
      slotDuration: '00:30:00',
      slotLabelInterval: 30,
      eventClick: async (ev) => {
        if(ev.event.id === 'Cita'){
          const tr = this.trSv.instant('DESEA_REMOVER_LA_CITA');
          const {role} = await this.uiSv.presentAlert(tr);
          if(role === 'accept'){
            this.removeDate();
          }
        }
      },
      dateClick: (ev) => {
        if(this.calendar.currentData.currentViewType === 'dayGridMonth'){
          this.clickedDate(ev, 'timeGrid');
        }else{
          if(this.blockDays){
            this.selectDay(ev);
          }
        }
      },
      businessHours: this._businessHours,
      eventSources: {
        events: this._events,
      }
    });
    this._calendarMonth = this.getCalendarDate().getMonth();
    this.calendarSetting();
    return true;
  }

  controlChange(direction){
    const dayGridMonth = this.calendar.currentData.currentViewType === 'dayGridMonth';
    direction === 'next' ? this.calendar.next() : this.calendar.prev();
    this.calendarSetting();
    const changedMonth = this.getCalendarDate().getMonth();
    if(this._calendarMonth !== changedMonth){
      this._calendarMonth = changedMonth;
      this.calendarSv.emitNewDate.emit(this.getCalendarDate());
    }
    // dayGridMonth && this.calendarSv.emitNewDate.emit(this.calendar.getDate());
  }

  getCalendarDate(): Date{
    return this.calendar.getDate();
  }

  canDate(hour, minute, actualDay, el?){
    const calendarDateFormat = this.calendar.getDate();
    const calendarDate = new Date(calendarDateFormat);
    const actualDate = new Date();
    if(calendarDate.getDate() < actualDate.getDate()){
      return false;
    }
    
    const hoursToMs12 = Number(hour) * 3600000;
    const minutesToMs12 = Number(minute) * 60000;
    const duration = this._duration * 60000;
    const dateToMs = hoursToMs12 + minutesToMs12; //hora que desea ser agendada
    let isOutOfService = false;
    let canDate = false;
    let reserved = false;
    let difference = 0;
    const viewDays = this._businessHours.filter((schedule) => schedule.daysOfWeek.find(day => Number(day) === actualDay));
    for (const schedule of viewDays ) {
      const { initToMs, endTimeToMs } = this.timeToMs(schedule);
      if(dateToMs >= initToMs && dateToMs + duration <= endTimeToMs){
        canDate = true;
        break;
      }
    }
    
    for (const schedule of this._reserved) {
      const { initToMs, endTimeToMs } = this.timeToMs(schedule);
      if(schedule.date.toISOString() === calendarDate.toISOString()){
        if(
            (dateToMs >= initToMs && dateToMs < endTimeToMs) //verifica si la agenda comienza desde otra reserva 
            || (dateToMs + duration > initToMs && dateToMs + duration < endTimeToMs) //Verifica si la agenda obstruye un horario ya agendado por otra persona
            || (dateToMs < initToMs && dateToMs + duration > endTimeToMs)
        ){
          difference = (endTimeToMs - (dateToMs + duration)) / 60000;
          reserved = true;
          canDate = false;
          if(schedule.title) isOutOfService = true;
          break;
        }
      }
    }

    if(reserved){
      if(el){
        const sign = Math.sign(difference); 
        let durationToHeight = 0;
        let directionBool = true;
        let direction = '';
        let style = `height: ${ this._heightContent }px;`;
        let totalHg = 0;
        if(sign === 1){
          directionBool = false;
          durationToHeight = (((this._duration - difference) / 60) * 24) * 2;
        }else{
          directionBool = true;
          durationToHeight = (((this._duration + difference) / 60) * 24) * 2;
        }
        totalHg = this._heightContent - durationToHeight;
        if(durationToHeight >= totalHg){
          if((this._heightContent / 4) === totalHg){
            direction = directionBool ? 'bottom' : 'top';
          }else{
            if(directionBool){
              direction = 'top'
            }else{
              direction = 'bottom'
            }
          }
        }else{
          if((this._heightContent / 4) === totalHg){
            direction = 'top'
          }else{
            if(!directionBool){
              direction = 'bottom'
            }else{
              direction = 'top'
            }
          }
        }
        style += `background: linear-gradient(to ${direction}, ${durationToHeight > totalHg ? '#f00' : '#0f0'} ${durationToHeight > totalHg ? durationToHeight : totalHg}px, ${durationToHeight > totalHg ? '#0f0' : '#f00'} ${durationToHeight > totalHg ? totalHg : durationToHeight}px);`;
        el.setAttribute('style', style);
      }else{
        const msgMeet = isOutOfService ? 'se encuentra fuera de servicio' : 'ya se encuentra cubierto por otra cita'
        const tr = this.trSv.instant('EL_HORARIO_SELECCIONADO');
        const trSc = this.trSv.instant('POR_FAVOR_SELECCIONA_OTRO_HORARIO');
        this.uiSv.presentAlert(`${tr} ${msgMeet}. ${trSc}`);
      }
    }
    return canDate;
  }

  removeDate(){
    const event = this.calendar.getEventById('Cita');
    if(event){
      event.remove();
    }
  }

  timeToMs(schedule){
    const splitedStartTime = schedule.startTime.split(':');
    const splitedEndTime = schedule.endTime.split(':');
    const initStartHour = Number(splitedStartTime[0]) * 3600000;
    const initStartMin = Number(splitedStartTime[1]) * 60000;
    const endStartHour = Number(splitedEndTime[0]) * 3600000;
    const endStartMin = Number(splitedEndTime[1]) * 60000;
    const initToMs = initStartHour + initStartMin;
    const endTimeToMs = endStartHour + endStartMin;
    return { initToMs, endTimeToMs }
  }

  async selectDay(ev){
    // let blockedTurn = false;    
    if(ev.jsEvent.target.className === 'fc-non-business'){
      const tr = this.trSv.instant('LO_SENTIMOS_EL_SERVICIO_NO_ESTA_DISPONIBLE_EN_EL_HORARIO_SELECCIONADO');
      return this.uiSv.presentAlert(tr, false);
    }
    const startHour = new Date(ev.dateStr);
    const canDate = this.canDate(startHour.getHours(), startHour.getMinutes(), startHour.getDay());
    if(!canDate){
      return;
    }
    startHour.setMinutes(startHour.getMinutes() + this._duration);
    const beginHour = startHour.getHours();
    const initMin = startHour.getMinutes();
    const fixedBeginHour = this.fixHours(beginHour);
    const fixedBeginMinutes = this.fixHours(initMin);
    const splitedTime = ev.dateStr.split('T')
    const body = {
      id: 'Cita',
      title: 'Cita', // a property!
      start: `${splitedTime[0]}T${splitedTime[1].slice(0,5)}`, // a property!
      end: `${splitedTime[0]}T${fixedBeginHour}:${fixedBeginMinutes}`, // a property! ** see important note below about 'end' **
      className: 'dateEvent'
    }
    this.removeDate();
    this._body = {
      startHour: splitedTime[1].slice(0,5),
      endHour: `${fixedBeginHour}:${fixedBeginMinutes}`,
      duration: this._duration,
      serviceId: this.operator.serviceId,
      operatorId: this.operator.id,
      date: startHour.toLocaleDateString()
    }
  
    this.calendar.addEvent(body);
    const {data, role} = await this.uiSv.showModal(ShowTermsComponent, {}, 'modalAdmin');
    if(data === 'accept'){
      const trSc = this.trSv.instant('LA_CITA_SERA_AGENDADA_PARA_LAS');
      const trTo = this.trSv.instant('HASTA_LAS');
      const trAg = this.trSv.instant('AGENDAR');
      const { role } = await this.uiSv.presentAlert(`${trSc} ${splitedTime[1].slice(0,5)} ${trTo} ${fixedBeginHour}:${fixedBeginMinutes}`, true, trAg);
      if(role === 'accept'){
        await this.sendMeet();
      }else{
        this.removeDate();
      }
    }else{
      this.removeDate();
    } 
  }

  fixHours(time){
    const fixTime = time < 10 ? `0${time}` : time;
    return fixTime
  }

  async clickedDate(ev, view){
    const savedDate = this.calendar.getDate();
    this.setCalendar();
    this.calendar.gotoDate(savedDate);
    if(ev){
      let goDate = new Date(ev.dateStr);
      goDate.setDate(goDate.getDate() + 1);
      const actualDate = new Date();
      if(goDate < actualDate && this.blockDays){
        const msg = this.trSv.instant('POR_FAVOR_SELECCIONE_UNA_FECHA_ACTUAL_O_MAYOR');
        await this.uiSv.presentAlert(msg);
        return false;
      }
      const schedule = this._eventTime.find(schedule => schedule.date === ev.dateStr);
      if(!schedule || schedule.available){
        this.calendar.changeView(view);
        this.calendar.gotoDate(ev.dateStr);
      }else{
        const tr = this.trSv.instant('LO_SENTIMOS_ESTE_DIA_NO_TIENE_UN_HORARIO_DISPONIBLE_POR_FAVOR_SELECCIONA_OTRO_DIA')
        this.uiSv.presentAlert(tr);
      }
    }else if(view){
      this.calendar.changeView(view);
    }
  }

  calendarSetting() {
    this.calendar.updateSize();
    this.calendar.render();
    setTimeout(() => {
      const td = document.querySelectorAll('.fc-timegrid-slot');
      const monthDays = document.querySelectorAll('.fc-daygrid-day');
      const actualDate = new Date();
      this.disableUnAvailableDates();
      
      if(this.blockDays){
        monthDays.forEach((item: any) => {
          const date = new Date(item.dataset.date);
          date.setDate(date.getDate() + 1)
          if(date < actualDate){
            item.classList.add('disableDayOfCalendar');
          }
        })

        td.forEach((item) => {
          item.addEventListener('mouseenter', (ev: any) => {
            if(ev.target.tagName === 'TD' && ev.target.classList.contains('fc-timegrid-slot-lane')){
              this.setContent(ev.target);
            }
          })
          item.addEventListener('mouseleave', (ev) => {
            this.removeDiv(ev.target);
          })
        })
      }
      
      
    })
    this.loading = false;
    return true;
  }

  disableUnAvailableDates(){
    this._eventTime.map(item => {
      const el = document.querySelector(`[data-date="${item.date}"]`);
      if(el && !item.available){
        el.classList.add('unAvailableDate');
      }
    })
  }

  removeDiv(el){
    const divisor = document.getElementById('divisor');
    if(divisor) el.removeChild(divisor);
  }

  setContent(el){
    const splitedHour = el.dataset.time.split(':');
    const hour = splitedHour[0];
    const min = splitedHour[1];
    const actualDay = new Date(this.calendar.getDate()).getDate();
    el.setAttribute('style', 'position: relative');
    const contentEl = document.createElement('DIV');
    const style = `height: ${ this._heightContent }px`;
    contentEl.setAttribute('style', style);
    contentEl.setAttribute('id', 'divisor');
    contentEl.setAttribute('class', 'divisorTime');
    el.appendChild(contentEl);
    this.canDate(hour, min, actualDay, contentEl)
  }

  async sendMeet(){
    if(!this._body){
      const tr = this.trSv.instant('DEBES_AGENDAR_ANTES_DE_CONTINUAR');
      return await this.uiSv.presentAlert(tr);
    }
    this.emitMeet.emit(this._body);
  }
}
