import { Directive, Input, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appInsertDetail]'
})
export class InsertDetailDirective implements OnInit{
  @Input() detail: string = '';

  constructor(
    private el: ElementRef
  ) {
   
  }

  ngOnInit(): void {
    const div: HTMLDivElement = this.el.nativeElement;
    const replacedDetail = this.detail.replace(/\n/g, '</br>');
    div.innerHTML = `<span>${replacedDetail}</span>`;
  }
}
