import { Component, Input, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { IonSlides, NavController } from '@ionic/angular';
import { CartServiceService } from 'src/app/services/cart-service.service';
import { ParamServicesService } from 'src/app/services/param-services.service';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-slides-card-home',
  templateUrl: './slides-card-home.component.html',
  styleUrls: ['./slides-card-home.component.scss'],
})

export class SlidesCardHomeComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('slideProductsSlide') slideProducts: IonSlides;
  @Input() data: any;
  @Input() showMore: any = true;
  @Input() products: any[] = undefined;
  @Input() route: string = '';
  @Input() infoSection: any = undefined;
  @Input() catalogueId: string;
  public slidesOpts = {
    breakpoints: {
      320: {
        slidesPerView: 2.1,
        spaceBetween: 0,
      },
      420: {
        slidesPerView: 3.1,
        // spaceBetween: 20,
      },
      700: {
        slidesPerView: 3,
        // spaceBetween: 20,
      },
      1270: {
        slidesPerView: 4,
        spaceBetween: 0,
      },
    }
  }
  private _subscribable: Subscription = new Subscription();
  public buyControl: any = undefined;
  public fixedProducts = [];
  public reachedEnd = false;
  public isStart = true;

  constructor(
    private navCtrl: NavController,
    private cartSv: CartServiceService,
    private paramSv: ParamServicesService,
    private loginSv: LoginService
  ) { }

  ngOnInit() {
    if(this.paramSv.buyControl){
      this.buyControl = this.paramSv.buyControl;
      this.fixProducts();
    }else{
      this._subscribable.add(this.paramSv.emitImagesObs.subscribe((item: any) => {
        if(item.name === 'buyControl' && !this.buyControl){
          this.buyControl = this.paramSv.buyControl;
          this.fixProducts();
        }
      }))
    }
  }

  ngAfterViewInit(): void {
    setTimeout(async() => {
      this.reachedEnd = await this.slideProducts.isEnd();
    })
  }

  async changedSlide(ev){
    this.reachedEnd = ev.el.dom7ElementDataStorage.swiper.isEnd;
    if(!this.reachedEnd || this.reachedEnd){
      this.isStart = ev.el.dom7ElementDataStorage.swiper.isBeginning;
    }
  }

  async fixProducts(){
    this.fixedProducts = this.paramSv.fixProducts(this.products, this.loginSv.user);
  }

  ngOnDestroy(): void {
    this._subscribable.unsubscribe();
  }

  slideTo(direction: string) {
    direction == 'next' ? this.slideProducts.slideNext() : this.slideProducts.slidePrev();
  }

  goTo(){
    this.cartSv.clearFilter();
    let filters = {};
    if(this.route === 'marketplace'){
      if(this.data?.type === 'promotionalProducts'){
        this.cartSv.marketplaceFilers.promo = '1';
      }else{
        if(this.data?.type === 'categoriesProducts'){
          this.cartSv.marketplaceFilers.category = this.catalogueId;
        }else if(this.data?.type === 'sponsor' || this.infoSection?.type === 'sponsor'){
          this.cartSv.marketplaceFilers.sponsor = this.data?.sponsor || this.infoSection.sponsor;
        }else if(this.data?.type === 'subscription'){
          this.cartSv.marketplaceFilers.subscription = 1;
        }else{
          console.log('a');
          this.cartSv.marketplaceFilers.orden = this.data?.type || '';
        }
      }
      filters = this.cartSv.marketplaceFilers;
    }
    this.navCtrl.navigateForward(this.route, {queryParams: filters});
  }
}
