import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ParamServicesService } from 'src/app/services/param-services.service';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
})
export class SplashComponent implements OnInit {
  splashImg: any;
  img: any;
  darkColor: string;
  color: string;

  constructor(
    private paramSv: ParamServicesService,
    private modalController: ModalController,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  async ngOnInit() {
    try{
      const domainName = await this.paramSv.getOnlyParam('domainName');
      const title = document.getElementById('titlePageIndex') as HTMLElement;
      title.innerText = domainName;
      console.log(this.paramSv.domainName);
      console.log(domainName);
      if(!this.paramSv.domainName) this.paramSv.domainName = domainName;
      console.log(this.paramSv.domainName);

      // const ogTitle = document.getElementById('og-title') as HTMLElement;
      // this.renderer.setAttribute(ogTitle, 'content', domainName);
      const colors = await this.paramSv.getOnlyParam('colors');
      const backgroundSplash: any[] = colors ? JSON.parse(colors) : undefined;
      if(backgroundSplash){
        const splashColor = backgroundSplash.find(item => item.name === '--splash');
        if(splashColor){
          this.elementRef.nativeElement.style.setProperty('--splash', splashColor.color);
          this.elementRef.nativeElement.style.setProperty('--darkSplashr', splashColor.darkColor);
        }
      }
      const data = await this.paramSv.getImages('5', '0') || [];
      data.map(item => {
        this.splashImg = item.images;
        this.img = this.splashImg[0];
      });
      
      const paramSv: Subscription = this.paramSv.emitImagesObs.subscribe(async (data) => {
        if(data === 'finish') {
          paramSv.unsubscribe();
          await this.modalController.dismiss();
        };
      });
      await this.paramSv.getParams();
      await this.paramSv.getImages();
    }catch(err){
      console.error(err);
    };
  };
}
