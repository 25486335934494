import { Component, OnInit, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Notifications } from 'src/app/interfaces/interfaces';
import { NotificationsService } from 'src/app/services/notifications.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  @ViewChild('infiniteScroll') infiniteScroll;

  public defaultImg = environment.defaultImg;

  constructor(
    public notificationSv: NotificationsService,
    private popoverController: PopoverController
  ) {}
  
  async ngOnInit() {
    await this.notificationSv.getNotifications();
  }

  async loadData(inetersector) {
    inetersector.disconnect();
    const load = await this.notificationSv.getNotifications();
    if(!load){
      inetersector.complete();
    }else{
      inetersector.observe();
    }
  }

  async openNotification(notification: Notifications){
    notification.view = 1;
    await this.notificationSv.goToNotification(notification);
    await this.popoverController.dismiss();
  }

  async markAsSeen(notification: Notifications){
    notification.view = 1;
    await this.notificationSv.marAsSeen(notification.id);
    await this.notificationSv.totalNotifications();
  }

  async changeTypeSeen(ev){
    this.notificationSv.changeType(ev.detail.value);
    this.infiniteScroll.visible = true;
  }

  async markAllAsRead(){
    this.notificationSv.markAllAsRead();
  }

}
