import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ParamServicesService {
  public colors = [];
  private _paypal = undefined
  public buttonsHeader = undefined;
  public emitImagesSrc = new Subject();
  public emitImagesObs = this.emitImagesSrc.asObservable();
  public uriBanner = environment.uriBanner;
  public uriImg = environment.uriImg
  public domainId = environment.domainId;
  public savedLogo: any[] = [];
  public headerParams: any = {};
  public homeSections: any = [];
  public homeSectionsSrc = new Subject();
  public footerParams: any = [];
  public carrousel: any[];
  public requiredAuth: boolean = undefined;
  public buttons: any = undefined;
  public headerHeight: number = undefined;
  public footerHeight: number = undefined;
  public headerOpts: any[] = [];
  public dispatch: any = undefined;
  public numWpp: any = undefined;
  public msgWpp: any = undefined;
  public msgGalleryWpp: any = undefined;
  public wpp: any = undefined;
  public domainName: any = undefined;
  public typeSliderPrimary: string = undefined;
  public typeSliderSecondary: string = undefined;
  public availableViews: any = undefined;
  public wallet: any = undefined;
  public existDistributorSignUp: any = undefined;
  public existDistributorSignUpRes: any = undefined;
  public existDistributorSignUpSrc = new Subject();
  public existDistributorSignUpObs = this.existDistributorSignUpSrc.asObservable();
  public styles: any[] = undefined;
  public styleCard: any = undefined;
  public defaultCurrency: any = undefined;
  public loginOptions: any = undefined;
  public cardMessages: any = undefined;
  public typeCategory: any = undefined;
  public typeCatalogue: any = undefined;
  public buyControl: any = undefined;
  public showAcceptModalExplore: any = undefined;
  public distributor: any = undefined;
  public activeWpp: any = undefined;
  public detailStyle: any = undefined;
  public typeRegisterText: any = undefined;
  public ableLogin: any = undefined;
  public backBtn: any = undefined;
  public toogleMenu = false;
  public headerParam = [
    {
      text: 'Buscador Inteligente',
      activate: true
    },
    {
      text: 'Multimoneda',
      activate: true
    },
    {
      text: 'Boton Contacto',
      activate: true
    },
    {
      text: 'Magazine',
      activate: true
    },
    {
      text: 'Carrito',
      activate: true
    },
    {
      text: 'Notificaciones',
      activate: true
    },
  ];

  constructor(
    private httpSv: HttpService,
    private navCtrl: NavController
  ) { }

  set paypal(value){
    this._paypal = value;
  }
  get paypal(){
    return this._paypal;
  }

  async getParams(){
    try{
      const res: any = await this.httpSv.itemIndex('client/parameter', this.domainId);
      await this.getHome();
      this.returnParam(res.data);
      return true;
    }catch(err){
      console.error(err);
    }
  }

  async getOneParameter(){
    try{
    }catch(err){
      console.error(err);
    }
  }

  async returnParam(data){
    try{
      this.headerParams = data.find(item => item.name === 'headerParams');
      this.footerParams = data.find(item => item.name === 'footerOpts');
      this.distributor = data.find(item => item.name === 'distributor')?.value;
      this.distributor && (this.distributor = JSON.parse(this.distributor));
      this.existDistributorSignUp = this.distributor?.value;
      this.existDistributorSignUpRes = this.existDistributorSignUp;
      this.existDistributorSignUpSrc.next(this.existDistributorSignUp);
      this.buttons = data.find(item => item.name === 'buttons');
      const domainName = data.find(item => item.name === 'domainName');
      this.domainName = domainName?.value;
      console.log(this.domainName);
      
      data.map(item => {
        if(item?.value){
          let value = (item.type.toLowerCase() === 'json') ? JSON.parse(item.value) : item.value;
          item.value = value;
          if(item.name === 'colors'){
            this.colors = value;
          }else if(item?.name === 'logo'){
            this.savedLogo = value;
          }else if(item.name === 'sliderPrincipal' || item.name === 'sliderSecundario'){
            const itemValue = item.value;
            const valueType = itemValue.value;
            item.name === 'sliderPrincipal' 
            ? this.typeSliderPrimary = valueType
            : this.typeSliderSecondary = valueType;
          }else if(item.name === 'redirectTo'){
            this.requiredAuth = value === 'requiredAuth' ? true : false;
          }else if(item.name === 'views'){
            this.availableViews = { name: item.name, value: item};
          }else if(item.name === 'wallet'){
            this.wallet = { name: item.name, value: item}
          }else if(item.name === 'styles'){
            this.styles = value;
            this.styleCard = this.styles.find(x => x.text === 'Card productos')?.selected || '';
          }else if(item.name === 'defaultCurrency'){
            value.change = Number(value.change);
            this.defaultCurrency = value;
          }else if(item.name === 'login'){
            if(value.social){
              value.social = value.social.filter(item => item.value);
            }else{
              value = value.filter(item => item.value);
            }
            this.loginOptions = value;
          }else if(item.name === 'cardMessages'){
            this.cardMessages = value;
          }else if(item.name === 'categoryStyle'){
            this.typeCategory = value;
          }else if(item.name === 'catalogueStyle'){
            this.typeCatalogue = value;
          }else if(item.name === 'buyControl'){
            this.buyControl = value;
          }else if(item.name === 'showAcceptModalExplore'){
            this.showAcceptModalExplore = value;
          }else if(item.name === 'dispatch'){
            !this.dispatch && (this.dispatch = value);
          }else if(item.name === 'activeWpp'){
            this.activeWpp = item?.value === 'false' ? false : true;;
          }else if(item.name === 'wpp' && !this.wpp){
            this.wpp = item?.value;
          }else if(item.name === 'detailStyle'){
            this.detailStyle = Number(value.type);
          }else if(item.name === 'typeRegisterText'){
            this.typeRegisterText = value;
          }else if(item.name === 'ableLogin'){
            if(!this.ableLogin) this.ableLogin = value;
          }else if(item.name === 'backBtn'){
            this.backBtn = value;
          }else if(item.name === 'paypal'){
            this.paypal = value;
          }
          this.emitImagesSrc.next({ name: item.name, value: item});
        }
      });
      this.emitImagesSrc.next('finish');
      return true
    }catch(err){
      console.error(err);
    }
  }

  setLogo(type: string){
    const savedLogos = this.savedLogo;
    const logoFt = savedLogos?.find(item => item.name.toLowerCase() === type.toLocaleLowerCase());
    if(logoFt){
      const logo = `${this.uriBanner}${logoFt.file?.file}`;
      return logo;
    }
  }

  async getHome(){
    try{
      const res: any = await this.httpSv.itemIndex('client/homePage', this.domainId);
      this.homeSections = res.data;
      this.emitImagesSrc.next({ name: 'homeSections', value: res.data });
      return true;
    }catch(err){
      console.error(err);
    }
  }

  async getOnlyParam(name){
    const res: any = await this.httpSv.getParam(name, this.domainId);
    return res?.value;
  }

  async getImages(orden = '', infoCarousel = ''){
    try{
      const res: any = await this.httpSv.itemIndex(`client/banner`, `${this.domainId}/${orden}/${infoCarousel}`);
      const images = res.data;
      if(!images) return;
      images.map(item => {
        item.images = item.images.map((image) => {
          image.url = `${this.uriBanner}${image.file}`;
          return image;
        });
        return item;
      });
      this.carrousel = images.filter(item => item.name === 'carrousel');
      if(this.carrousel.length){
        this.requiredAuth = this.carrousel[0].type === 'requiredAuth' ? true : false;
        if(this.requiredAuth) this.navCtrl.navigateForward('auth');
      }
      return images;
    }catch(err){
      console.error(err);
    }
  }

  fixProducts(products: any[], user){
    if(!products) return products;
    const rolId = user?.rolId;
    const dist = (rolId === 7 || (this.existDistributorSignUp && rolId === 3));
    const client = (rolId !== 7 && rolId !== 3) || (rolId === 3 && !this.existDistributorSignUp);
    if(this.buyControl?.hiddeZeroExistence){
      const newArr = [];
      products.map(item => {
        if(client){
          if((item.countHidden && (item.existence >= 0 || item.existence === null)) || (!item.countHidden && (item.existence !== 0))){
            newArr.push(item);
          }
        }else if(dist){
          if((item.countHidden && (item.existenceWSale >=0 || item.existenceWSale === null || item.existence >= 0 || item.existence === null)) || (!item.countHidden && (item.existenceWSale !== 0 || item.existence !== 0))){
            newArr.push(item);
          }
        }
      })
      products = newArr;
    }else{
      products.map(item => {
        if((!item.countHidden && client && item?.existence === 0)
          || (dist && (item?.existenceWSale === 0 && item?.existence === 0))  
        ){
          item.noExistence = true;
        }
      })
    }
    return products;
  }

  async setProperties(headerValue){
    try{
      let menuOptions = undefined;
      
      let placeholder = '';
      let typeSearchbar = '';
      let optsMenu = undefined;
      this.buttonsHeader =  undefined;
      if(typeof headerValue === 'string'){
        headerValue = JSON.parse(headerValue);
      }
      const data = headerValue;
      if(data){
        if(data?.buttons?.length){
          data.buttons = data?.buttons.filter(item => item?.name);
        }
        this.buttonsHeader = data?.buttons || [];
        placeholder = data?.placeholder;
        data.headerParam.map((item, index) => {
          if( (this.headerParam[index].text === item.text) && !item.activate){
            this.headerParam[index].activate = false;
          }
        });
        // Cual tipo de catalogo pedir, todos o los destacados
        typeSearchbar = data.typeSearchbar || '';
        if(!this.headerOpts.length){
          menuOptions = await this.httpSv.itemIndex('client/homePage/header', this.domainId);
          optsMenu = menuOptions.data;
        }else{
          menuOptions = this.headerOpts;
          optsMenu = menuOptions;
        }
        this.headerOpts = optsMenu;
        return { placeholder, typeSearchbar, optsMenu, buttonsHeader: this.buttonsHeader };
      }
    }catch(err){
      console.error(err);
    }
  }

  async getGiftCards(){
    let card = await this.getImages('10');
    if(card) card = card[0];
    card.images = card.images.filter(item => item.color);
    const giftCard = card.images;
    giftCard.map(item => {
      item.counter = 0
      item.description = Number(item.description);
    });
    return giftCard;
  }
}

