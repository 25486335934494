import { Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { CartServiceService } from 'src/app/services/cart-service.service';
import { ParamServicesService } from 'src/app/services/param-services.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { Params } from '@angular/router';
import { UiServices } from 'src/app/services/ui-services';
import { MultioComponent } from '../multio/multio.component';
import { LoginService } from 'src/app/services/login.service';
import { NotificationsComponent } from '../notifications/notifications.component';
import { NotificationsService } from 'src/app/services/notifications.service';
import { PwaService } from 'src/app/services/pwa.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-headercomponent',
  templateUrl: './headercomponent.component.html',
  styleUrls: ['./headercomponent.component.scss'],
})
export class HeadercomponentComponent implements OnInit, OnDestroy {
  @ViewChild('addCart') addCart: ElementRef<HTMLDivElement>;
  @ViewChild('colSearchbar', { static: false }) colSearchbar: any;

  public loadingIndicator = false;
  public toogleSearchbar = false;
  public searchIcon: string = './assets/icons/buscador.svg';
  public cartIcon: string = './assets/icons/';
  public multimonedaIcon: string = './assets/icons/moneda.svg';
  public optsMenu: any[] = [];
  public logined: string;
  public totalCart: number = 0;
  public logo: string;
  public domainId = environment.domainId;
  public msgWpp: string = '';
  public textFloatingBg = '';
  public headerSuscription = new Subscription();
  public numWpp = '';
  public search = '';
  public floatingColor = '';
  public placeholder = '';
  public emited = false;
  public addSubscription: Subscription = new Subscription();
  public buttons = [];
  public typeSearchbar: any = '';

  //Variables para el estilo del wpp flotante
  public showWpp = undefined;
  public styleWpp: any = '';
  public iconWpp: any = '';
  public msgFloatingWpp: any = '';
  public childBottomPosition = false;
  public total = 0;
  public actualRoute = undefined;;
  public dinamicHeader = false;
  public headerStyle = undefined;

  constructor(
    private navCtrl: NavController,
    private httpSv: HttpService,
    private renderer: Renderer2,
    private uiSv: UiServices,
    private platform: Platform,
    public cartSv: CartServiceService,
    public paramSv: ParamServicesService,
    public loginSv: LoginService,
    public notificationSv: NotificationsService,
    public pwaSv: PwaService,
    private menuController: MenuController,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    if(this.platform.is('pwa')){
      this.pwaSv.backbutton = true;
    }
  }

  async ngOnInit() {
    try{
      if (!isPlatformBrowser(this.platformId)) {
        return false;
      }
      this.actualRoute = window.location.pathname;
      this.addSubscription.add(
        this.cartSv.totalSrcObs
          .subscribe((data: any) => {
            const element = this.addCart?.nativeElement;
            if(element && data.emit){
              this.renderer.setStyle(element, 'display', 'block');
              this.renderer.addClass(element, 'addedCart');
              setTimeout(() => {
                this.renderer.removeStyle(element, 'display');
                this.renderer.removeClass(element, 'addedCart');
              }, 3000);
            }
            this.total = data.total;
          })
      );
      let headerValue = this.paramSv?.headerParams?.value;
      const savedLogo = this.paramSv.savedLogo;
      (savedLogo?.length) && this.setLogo();
      if(headerValue){
        headerValue = headerValue;
        this.setProperties(headerValue);
      }

      //Agrego suscipciones al contenedor de las mismas para luego
      //desubscribirme de ellas.
      this.addSubscription.add(
        this.httpSv.loadingIndicatorObs
          .subscribe((data: boolean) => {
            this.loadingIndicator = data;
          })
      );

      if(this.paramSv.styles){
        this.setStylesFromParams();
      }

      this.paramSv.activeWpp && (this.showWpp = this.paramSv.activeWpp);
      this.setStyleWpp();

      this.headerSuscription = this.paramSv.emitImagesObs.subscribe(async (item: any) => {
        if(item?.name === 'logo'){
          let headerValue = this.paramSv?.headerParams?.value;
          this.setProperties(headerValue);
          this.setLogo();
        }else if(item.name === 'activeWpp'){
          this.showWpp = this.paramSv.activeWpp;
        }else if(item.name === 'wpp'){
          if(!this.numWpp){
            this.setStyleWpp();
          }
        }else if(item === 'finish'){
          this.headerSuscription.unsubscribe();
        }else if(item.name === 'styles'){
          this.setStylesFromParams();
        }
      })
    }catch (err){
      console.error(err);
    };
  }

  async toogleMenu(){
    this.paramSv.toogleMenu = !this.paramSv.toogleMenu;
  }

  setStylesFromParams(){
    const cart = this.paramSv.styles.find(item => item.text === 'Carrito')?.selected;
    this.headerStyle = this.paramSv.styles.find(item => item.text === 'Header')?.selected;
    this.cartIcon += cart || 'carrito2.svg';
  }

  setStyleWpp(){
    const data = this.paramSv.wpp;
    if(data){
      this.numWpp = data.phone;
      this.msgWpp = data.msg;
      if(data.styleWpp){
        const { style, icon, msg, textFloatingColor, textFloatingBg } = data.styleWpp;
        this.styleWpp = style;
        this.iconWpp = icon;
        this.msgFloatingWpp = msg;
        this.floatingColor = textFloatingColor;
        this.textFloatingBg = textFloatingBg;
      }
      this.paramSv.msgGalleryWpp = data.msgGallery;
      this.paramSv.msgWpp = this.msgWpp;
      this.paramSv.numWpp = this.numWpp;
    } 
  }

  async showNotifications(ev){
    await this.uiSv.showPopover(NotificationsComponent, '', 'notification', ev);
    this.notificationSv.notifications = [];
    this.notificationSv.page = 1;
    this.notificationSv.totalPages = 1;
  }

  calcAxisY(ev){
    if(!ev.target.dataset.category){
      return false;
    }
    const splitedHeight = window.innerHeight / 2;
    if(ev.clientY > splitedHeight){
      this.childBottomPosition = true;
    }else{
      this.childBottomPosition = false;
    }
  }

  ngOnDestroy(): void {
    this.addSubscription?.unsubscribe();
  }

  setLogo(){
    const logoFt = this.paramSv.setLogo('Header');
    this.logo = logoFt;
  }

  async setProperties(headerValue){
    try{
      if(typeof headerValue === 'string'){
        headerValue = JSON.parse(headerValue);
      }
      const data = headerValue;
      if(data){
        const { optsMenu, placeholder, typeSearchbar, buttonsHeader } = await this.paramSv.setProperties(headerValue);
        this.optsMenu = optsMenu;
        this.placeholder = placeholder;
        this.typeSearchbar = typeSearchbar;
        this.buttons = buttonsHeader;
        this.cartSv.setCart();
      }
    }catch(err){
      console.error(err);
    }
  }
  
  async doSearch(){
    try{
      this.cartSv.marketplaceFilers.search = this.search;
      this.cartSv.marketplaceFilers.pag = 1;
      this.navCtrl.navigateForward('marketplace', { queryParams: this.cartSv.marketplaceFilers});
    }catch(err){
      console.error(err);
    }
  }

  async goTo(opts, item?, external?, child?){
    this.cartSv.clearFilter()
    let url = '/catalogue/' + opts?.catalogues?.id;
    if (opts.type === 'headersLink') {
      if(child){
        const text = child.target.outerText.toLowerCase().trim();
        const btnChilds: any[] = opts.headersLink.child;
        const btnChild = btnChilds.find(x => x.name.toLowerCase() === text);
        url = btnChild.redirectUrl;
      }else{
        url = opts.headersLink.redirectUrl;
      }
      this.navCtrl.navigateForward(url);
      return true;
    } else if (typeof opts === 'string') {
      this.navCtrl.navigateBack(opts);
      return true;
    } else if (opts.type === 'categories') {
      if (item) {
        this.cartSv.marketplaceFilers.category = item.id;
      } else {
        this.cartSv.marketplaceFilers.category = opts.relation;
      }
      this.doSearch();
      return true;
    }else if(opts.type === 'services' || opts.type === 'subscription'){
      if(opts.type === 'services'){
        this.cartSv.marketplaceFilers.services = 1;
      }else{
        this.cartSv.marketplaceFilers.subscription = 1;
      }
      this.doSearch();
      return true;
    }

    if(opts?.headersLink?.redirectUrl){
      url = '/catalogue/' + opts?.catalogues?.id
    }
    item = opts.type === 'services' ? undefined : item;
    child = opts.type === 'services' ? undefined : child;
    let query: Params;
    if(external){
      const type = !!url.includes('https');
      if(type){
        window.open(url, '_blank');
      }else{
        this.navCtrl.navigateForward(url);
      }
      return true;
    }
    if(item){
      query = {
        category: item.id
      }
      if(child && child.target.id){
        query.chCategory = child.target.id;
      }
    };
    await this.navCtrl.navigateForward(url, {queryParams: query});
    return true;
  }

  openWpp(){
    window.open(`https://wa.me/${this.numWpp}?text=${this.msgWpp}`, '_blank');
  }

  showSearchbar(){
    const el = this.colSearchbar.el;
    if(!this.toogleSearchbar){
      this.renderer.addClass(el, 'col-searchbar-show');
    }else{
      this.renderer.addClass(el, 'hideBar');
      setTimeout(() => {
        this.renderer.removeClass(el, 'col-searchbar-show');
        this.renderer.removeClass(el, 'hideBar');
      }, 500)
    }

    this.toogleSearchbar = !this.toogleSearchbar;
  }

  async openMulticurrency(ev){
    await this.uiSv.showPopover(MultioComponent, {}, '', ev);
  }
}
