import { Directive, ElementRef, Input, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[appHeaderStyles]'
})
export class HeaderStylesDirective {
  @Input() set headerStyle(value){
    if(value){
      if(value === 'dinamicHeader'){
        if(window.location.pathname === '/home'){
          this.typeStyle = value;
          this.renderer.addClass(this._el, 'dinamicHeader');
          this.renderer.addClass(this._el, 'ion-no-border');
        }
      }
    }
  }

  private typeStyle = undefined;
  private _el: ElementRef = undefined

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this._el = el.nativeElement;
  }

  @HostListener('window:ionScroll', ['$event'])
    setBackground(value){
      if(this.typeStyle === 'dinamicHeader'){
        if(value.detail.scrollTop !== 0){
          this.renderer.setStyle(this._el, 'background', 'var(--header)', 1);
          this.renderer.removeClass(this._el, 'ion-no-border');
        }else{
          this.renderer.addClass(this._el, 'ion-no-border');
          this.renderer.removeStyle(this._el, 'background');
        }
      }
    }


}
