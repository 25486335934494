import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ParamServicesService } from 'src/app/services/param-services.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss'],
})
export class SectionsComponent implements OnInit {
  @Input() home: any[];
  public domainId = environment.domainId;
  private _filter = {
    category: '',
    orden: '',
    promo: '',
    from: '',
    upTo: '',
    domainId: this.domainId
  }
  constructor(
    private navCtrl: NavController,
    public paramSv: ParamServicesService
  ) { }

  ngOnInit() {
    this.home = this.home.filter(item => {
      if((typeof item[item.type] === 'object' && item[item.type].length) || !item[item.type]){
        return item;
      }else if(item.type === 'sponsor'){
        if(item.sponsor.sponsors && item.sponsor.sponsors.length){
          return item;
        }
      }else if( item.type === 'specialButton'){
        return item;
      }
    });
  }

  trackByItems(index, item){
    return item.id ? item.id : item
  }

  goMarketplace(id){
    this._filter.category = id;
    const queryParams = this._filter;
    this.navCtrl.navigateForward('marketplace', {queryParams});
  }
}
