import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NavController } from '@ionic/angular';
import { CartServiceService } from 'src/app/services/cart-service.service';
import { UiServices } from 'src/app/services/ui-services';
import { MultioComponent } from '../multio/multio.component';

@Component({
  selector: 'app-menu-ssr',
  templateUrl: './menu-ssr.component.html',
  styleUrls: ['./menu-ssr.component.scss'],
})
export class MenuSsrComponent implements OnInit {
  @Input() colors = undefined;
  @Input() loginSv = undefined;
  @Input() paramSv = undefined;
  @Input() balanceSv = undefined;
  @Input() staticOpts = [];
  @Output() comprobeLogin = new EventEmitter();


  constructor(
    private cartSv: CartServiceService,
    private navCtrl: NavController,
    private uiSv: UiServices
  ) { }

  ngOnInit() {}

  closeMenu(ev?){
    if(ev){
      console.log(ev);
      if(ev.target.tagName !== 'ASIDE' && ev.target.tagName !== 'ION-BUTTON'){
        return false;
      }
    }
    this.paramSv.toogleMenu = !this.paramSv.toogleMenu;
  }

  async emitUrl(ev: any){
    const url = ev?.url;
    const currency = ev?.changeCurrency;
    if(currency){
      await this.uiSv.showPopover(MultioComponent, {}, '');
      return
    }
    if(url === 'logout'){
      await this.loginSv.logout();
      this.cartSv.logined = false;
      this.comprobeLogin.emit();
    }else if(url){
      this.navCtrl.navigateForward(url);
    }
    this.closeMenu();
  }

  async goTo(opts, categoryId?, childId?, external?){
    const queryParams: any = {};
    this.cartSv.clearFilter()
    childId && (queryParams.chCategory = childId);
    if(opts?.type === 'headersLink'){
      if(categoryId){
        const btn = opts.headersLink.child.find(x => x.id === categoryId);
        this.navCtrl.navigateForward(btn.redirectUrl);
      }else{
        const url = opts.headersLink.redirectUrl;
        this.navCtrl.navigateForward(url);
        return true;
      }
    }else if(opts.type === 'categories'){
      this.cartSv.marketplaceFilers.category = categoryId || opts.relation;
      this.doSearch();
    }else if(typeof opts === 'string'){
      this.navCtrl.navigateForward(opts);
    }else if(opts.type === 'catalogues'){
      const id = opts.relation;
      categoryId && (queryParams.category = categoryId);
      this.navCtrl.navigateForward(`catalogue/${id}`, {queryParams});
    }else if(opts.type === 'services'){
      if(categoryId){
        this.navCtrl.navigateForward(`catalogue/${categoryId}`, {queryParams});
      }else{
        this.cartSv.marketplaceFilers.services = 1;
        this.doSearch();
        return true;
      }
    }else if(opts.type === 'subscription'){
      this.cartSv.marketplaceFilers.subscription = 1;
      this.doSearch();
      return true;
    }

    if(external){
      const type = !!opts.includes('https');
      if(type){
        window.open(opts, '_blank');
      }else{
        this.navCtrl.navigateForward(opts);
      }
      return true;
    }
    await this.closeMenu();
  }

  async doSearch(){
    try{
      this.cartSv.marketplaceFilers.search = '';
      this.cartSv.marketplaceFilers.pag = 1;
      this.navCtrl.navigateForward('marketplace', { queryParams: this.cartSv.marketplaceFilers});
    }catch(err){
      console.error(err);
    }
  }
}
