import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss'],
})
export class PrimaryButtonComponent implements OnInit {
  @Input() text = undefined;
  @Input() typeBtn = undefined;
  @Input() fontWeight = undefined;
  @Input() color = undefined;
  @Input() disabled = undefined;
  @Input() fontSize = '.8rem';
  @Input() fullWidth = undefined;
  @Input() type = '';
  @Input() icon = '';
  @Input() noMargin: boolean = false;
  @Input() slot = 'start';
  @Input() height = '36px';
  @Input() fillType = undefined;
  @Output() emitClick = new EventEmitter();

  constructor() { }

  ngOnInit() {
    
  }

}
