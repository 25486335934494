import { Directive, ElementRef, Input } from '@angular/core';
import { ParamServicesService} from  'src/app/services/param-services.service';

@Directive({
  selector: '[appInstagramSrc]'
})
export class InstagramSrcDirective {

  constructor(
    private paramSv: ParamServicesService,
    private el: ElementRef<HTMLDivElement>
  ) {
    this.getIg();
  }

  async getIg(){
    const res = await this.paramSv.getOnlyParam('instagram');
    const data = JSON.parse(res);
    const div = document.createElement('DIV');
    div.dataset.mcSrc = data.value;
    this.el.nativeElement.appendChild(div);
  }
}
